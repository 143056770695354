import LinkButton from '@sdtech/sd-ui/dist/commonjs/components/LinkButton/LinkButton';
import React, { FunctionComponent } from 'react';
import CarouselBanner from '../carouselBanner/CarouselBanner';

interface AiBannerProps {
  assetsBaseUrl: string;
}

const AiBanner: FunctionComponent<AiBannerProps> = ({
  assetsBaseUrl,
}) => (
      <CarouselBanner
        className="sd-ai-banner"
        assetsBaseUrl={assetsBaseUrl}
        backgroundPosition="0"
        prefix="sciencedirect_ai"
        regionLabel="ScienceDirect AI"
        imgText="ScienceDirect AI"
      >
        <p>
          Instantly surface, cite, compare and
          explore trusted evidence from deep
          within peer-reviewed literature with
          ScienceDirect AI.
        </p>
        <LinkButton
          className="link-button-tertiary banner-button move-left u-margin-m-top-from-sm u-margin-s-top-from-xs"
          href="https://elsevier.shorthandstories.com/sciencedirect-ai/"
          target="_blank"
          isExternalLink
          size="small"
        >
          Explore ScienceDirect AI
        </LinkButton>
      </CarouselBanner>
  );

export default AiBanner;
